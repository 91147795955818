import React, {useContext} from 'react';
import {FiEdit} from "@react-icons/all-files/fi/FiEdit";
import {AiFillSetting} from "@react-icons/all-files/ai/AiFillSetting";
import {Link} from "react-router-dom";
import {BiBookBookmark} from "@react-icons/all-files/bi/BiBookBookmark";
import TableTwoBody from "../../CommonComponent/BootstrapTable2Body/TableTwoBody";
import {StudentContext} from "../OurStudentSection";
import {FaInfoCircle} from "@react-icons/all-files/fa/FaInfoCircle";

function StudentTable({studentList = []}) {
    const {
        singleStudentDetails,
        setSingleStudentDetails
    } = useContext(StudentContext)

    const columns = [{
        dataField: 'id',
        text: 'SL',
        sort: true,
        headerStyle: (colum, colIndex) => {
            return {
                width: '25px',
            };
        },
        formatter: (cell, row, rowIndex) => {
            return <>
                {rowIndex + 1}
            </>
        }
    },
        {
            dataField: 'student_image',
            text: 'Image',
            sort: true,
            headerStyle: (colum, colIndex) => {
                return {
                    width: '120px',
                };
            },
            formatter: (cell, row) => {
                return <><img src={cell} alt="" width="60px"/></>
            }
        }
        , {
            dataField: 'stu_a_student_info.student_full_name',
            text: 'Student Name',
            sort: true,
            headerStyle: (colum, colIndex) => {
                return {
                    width: '200px',
                };
            }, formatter: ((cell, row) => {
                return <>
                    {cell + " (" + row.id + ")"}
                </>
            })
        }, {
            dataField: 'cnfg_class_name.class_name',
            text: 'Class Name',
            sort: false,
            headerStyle: (colum, colIndex) => {
                return {
                    width: '100px',
                    textAlign: "center",
                };
            },
            style: {textAlign: "center"}
        }, {
            dataField: 'cnfg_class_wise_department.department_name',
            text: 'Department',
            sort: false,
            headerStyle: (colum, colIndex) => {
                return {
                    width: '100px',
                    textAlign: "center",
                };
            },
            style: {textAlign: "center"}
        }, {
            dataField: 'cnfg_class_segment.segment_name',
            text: 'Segment',
            sort: false,
            headerStyle: (colum, colIndex) => {
                return {
                    width: '100px',
                    textAlign: "center",
                };
            },
            style: {textAlign: "center"}
        }, {
            dataField: 'academic_year',
            text: 'Academic Year',
            sort: false,
            headerStyle: (colum, colIndex) => {
                return {
                    width: '100px',
                    textAlign: "center",
                };
            },
            style: {textAlign: "center"}
        }, {
            dataField: 'id',
            text: 'Action',
            headerStyle: () => {
                return {
                    width: '120px', textAlign: "center",

                }
            },
            style: {textAlign: "center"},
            formatter: ((cell, row) => {
                return <>
                    {/*subject configure Button*/}
                    <Link target={"_blank"} to={`/studentDetail/${row.id}`}>
                        <button title="Detail"
                                className="ms-2 bg-transparent iconActionButton border-0 text-success btn btn-primary"
                                onClick={e => {
                                    // set data
                                    setSingleStudentDetails(row)
                                }
                                }>
                            <FaInfoCircle/>
                        </button>
                    </Link>


                </>
            })

        }

    ];
    return (
        <>
            <TableTwoBody
                products={studentList}
                columns={columns}
                isBorderd={true}
            />
        </>
    );
}

export default StudentTable;