import React, {useContext, useEffect, useRef, useState} from 'react';
import {Button, Col, Form, Row} from "react-bootstrap";
import ApplicationUtilities from "../../../Utilities/ApplicationUtilities";

import ButtonLoader from "../../CommonComponent/ButtonLoader/ButtonLoader";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {classWiseStudentAction} from "../../../Redux/Action/StudentAction";
import {StudentContext} from "../OurStudentSection";


function StudentBackendFilterForm(props) {
    // hooks
    const btnRef = useRef()
    const dispatch = useDispatch()
    const departmentRef = useRef()
    const segmentRef = useRef()


    // hook form
    const {register, handleSubmit, getValues, reset, setValue, formState: {errors}} = useForm();
    //  states
    const [allDepartment, setAllDepartment] = useState([])
    const [allSegment, setAllSegment] = useState([])

// getting data form redux
    let allClass = useSelector(state => state.configurationSlice.allClass)

    //Context
    const {
        classWiseStudent,
        setClassWiseStudent
    } = useContext(StudentContext)


    // class on change
    const classOnChange = () => {
        const selectedClass = JSON.parse(getValues("class_id") || "{}")
        // set class wise department and segment
        setAllDepartment(selectedClass.cnfg_class_wise_departments || [])
        setAllSegment(selectedClass.cnfg_class_segments || [])
        document.getElementById("segmentForClassWiseStudent").selectedIndex = 0
        document.getElementById("departmentForClassWiseStudent").selectedIndex = 0

    }


    // form submit
    const onSubmit = async data => {
        // modifying data
        let updatedData = {
            ...data,
            class_id: JSON.parse(data.class_id || "{}").id
        }
        const payload = {
            data,
            reset,
            btnRef,
            setClassWiseStudent
        }
        payload.data = updatedData
        dispatch(classWiseStudentAction(payload))
    }
    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col md={3} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Academic Year <span>*</span></Form.Label>
                            <Form.Select
                                className={`${errors.academic_year ? "is-invalid" : ""}`}
                                {...register("academic_year", {
                                    required: true
                                })}
                            >
                                <option value="">Select Academic Year</option>
                                {ApplicationUtilities.years().map((value, key) => (
                                    <option value={value} key={key}>{value}</option>
                                ))}

                            </Form.Select>
                            {/*validation message*/}
                            {errors.academic_year && <span className="text-danger">
                                {errors.academic_year.type == "required" && "Please Give Academic Year."}
                                {errors.academic_year.type == "pattern" && "Please Provide A Valid Number."}
                                {errors.academic_year.type == "minLength" && "Please Provide Full Year."}
                            </span>}
                        </Form.Group>
                    </Col>
                    <Col md={3} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Class <span>*</span></Form.Label>
                            <Form.Select
                                {...register("class_id", {
                                    required: true,
                                    onChange: event => classOnChange()
                                })}
                            >
                                <option value="">Select...</option>
                                {allClass.map((singleClass, key) => (
                                    <option value={JSON.stringify(singleClass)}>{singleClass.class_name}</option>
                                ))}
                            </Form.Select>
                            {/*validation message*/}
                            {errors.class_id && <span className="text-danger">
                                {errors.class_id.type == "required" && "Please Select A Class."}
                            </span>}
                        </Form.Group>
                    </Col>
                    <Col md={3} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Department <span>*</span></Form.Label>
                            <Form.Select
                                id="departmentForClassWiseStudent"
                                {...register("department_id", {
                                    required: true
                                })}
                            >
                                <option value="">Select...</option>
                                {allDepartment.map((singleDepartment, key) => (
                                    <option value={singleDepartment.id}>{singleDepartment.department_name}</option>
                                ))}
                            </Form.Select>
                            {/*validation message*/}
                            {errors.department_id && <span className="text-danger">
                                {errors.department_id.type == "required" && "Please Select A Department."}
                            </span>}
                        </Form.Group>
                    </Col>
                    <Col md={3} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Segment / Year <span>*</span></Form.Label>
                            <Form.Select
                                id="segmentForClassWiseStudent"
                                {...register("class_segment_id", {
                                    required: true,
                                })}
                            >
                                <option value="">Select...</option>
                                {allSegment.map((singleSegment, key) => (
                                    <option value={singleSegment.id}>{singleSegment.segment_name}</option>
                                ))}
                            </Form.Select>
                            {/*validation message*/}
                            {errors.class_segment_id && <span className="text-danger">
                                {errors.class_segment_id.type == "required" && "Please Select A Segment."}
                            </span>}
                        </Form.Group>
                    </Col>

                </Row>


                <div>
                    <Button ref={btnRef}
                            type="submit"
                            className="submit btn-sm">
                        Submit
                        <ButtonLoader/>
                    </Button>
                </div>

            </form>
        </>
    );
}

export default StudentBackendFilterForm;