import React, {createContext, useEffect, useState} from 'react';
import {Card, Tab, Tabs} from "react-bootstrap";
import StudentBackendFilterForm from "./OurStudentSubComponent/studentBackendFilterForm";
import {getAllClassAction} from "../../Redux/Action/ClassAction"
import {getAllShiftAction} from "../../Redux/Action/ShiftAction";
import {getAllSectionAction} from "../../Redux/Action/SectionAction";
import {getAllStudentTypeAction} from "../../Redux/Action/StudentTypeAction";
import {getAllServiceChargeAction} from "../../Redux/Action/ServiceChargeAction";
import {useDispatch} from "react-redux";
import StudentTable from "./OurStudentSubComponent/StudentTable";


export const StudentContext = createContext()
function OurStudentSection(props) {

    //State
    const [classWiseStudent, setClassWiseStudent] = useState([])
    const [singleStudentDetails, setSingleStudentDetails] = useState({})

    const dispatch = useDispatch()
    // get necessary data lifecycle
    useEffect(() => {
        // get all class
        dispatch(getAllClassAction({}))

    }, [])

    return (

        <>
           <StudentContext.Provider value={{
               classWiseStudent,
               setClassWiseStudent,
               singleStudentDetails,
               setSingleStudentDetails
           }}>
               <Card>
                   <Card.Body>
                       <div className="d-flex align-items-center justify-content-between">
                           <h3>Our Student</h3>
                       </div>
                       <StudentBackendFilterForm/>
                   </Card.Body>
               </Card>
               <Card className="mt-3">
                   <Card.Body>
                       <StudentTable studentList={classWiseStudent}/>
                   </Card.Body>
               </Card>
           </StudentContext.Provider>
        </>
    );
}

export default OurStudentSection;