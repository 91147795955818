 const rootRoute = "https://www.app-api.kqbym.com/"
 //const rootRoute = "http://localhost:1111/"
 //const rootRoute = "http://192.168.0.105:1111/"

export const login = rootRoute + "user/login"
export const updateUserGeneralSetting = rootRoute + "user/updateUserGeneralSetting"
export const userPasswordChange = rootRoute + "user/userPasswordChange"
export const userLogOutFromAllDevice = rootRoute + "user/userLogOutFromAllDevice"
export const getAllUser = rootRoute + "user/getAllUser"
export const getAllRole = rootRoute + "user/getAllRole"
export const addUser = rootRoute + "user/addUser"
export const updateUserFromAdmin = rootRoute + "user/updateUserFromAdmin"
export const resetUserPasswordFromAdmin = rootRoute + "user/resetUserPasswordFromAdmin"

export const getAllClass = rootRoute + "config/getAllClass"
export const addClass = rootRoute + "config/addClass"
export const updateClass = rootRoute + "config/updateClass"

export const getAllServiceForCharge = rootRoute + "config/getAllServiceForCharge"
export const addServiceForCharge = rootRoute + "config/addServiceForCharge"
export const updateService = rootRoute + "config/updateService"

export const getAllSection = rootRoute + "config/getAllSection"
export const addSection = rootRoute + "config/addSection"
export const updateSection = rootRoute + "config/updateSection"


export const getAllShift = rootRoute + "config/getAllShift"
export const addShift = rootRoute + "config/addShift"
export const updateShift = rootRoute + "config/updateShift"
export const configureDepartment = rootRoute + "config/configureDepartment"
export const departmentDelete = rootRoute + "config/departmentDelete"
export const configureClassSegment = rootRoute + "config/configureClassSegment"
export const classSegmentDelete = rootRoute + "config/classSegmentDelete"
export const getSubjects = rootRoute + "config/getSubjects"
export const configureSubject = rootRoute + "config/configureSubject"
export const subjectUpdate = rootRoute + "config/subjectUpdate"
export const subjectDelete = rootRoute + "config/subjectDelete"
export const getAllStudentType = rootRoute + "config/getAllStudentType"

export const studentAdmit = rootRoute + "student/studentAdmit"
export const getClassWiseStudents = rootRoute + "student/getClassWiseStudents"
export const getSingleStudentDetail = rootRoute + "student/getSingleStudentDetail"
export const updateStudentInfo = rootRoute + "student/updateStudentInfo"
export const updateBirthRegImage = rootRoute + "student/updateBirthRegImage"
export const updateAdhaarCardImage = rootRoute + "student/updateAdhaarCardImage"
export const updateStudentGuardianInfo = rootRoute + "student/updateStudentGuardianInfo"
export const updatePreviousSchoolInformation = rootRoute + "student/updatePreviousSchoolInformation"
export const updateStudentBankInfo = rootRoute + "student/updateStudentBankInfo"
export const updateSessionalGovRegInfo = rootRoute + "student/updateSessionalGovRegInfo"
export const updateStudentAddress = rootRoute + "student/updateStudentAddress"
export const updateSessionalInfo = rootRoute + "student/updateSessionalInfo"
export const updateSessionalInfoImage = rootRoute + "student/updateSessionalInfoImage"








//Website api
export const getAllTeacher = rootRoute + "unprotected/website/staff/getTeacher"
export const addTeacher = rootRoute + "website/staff/addTeacher"
export const updateTeacher = rootRoute + "website/staff/updateTeacher"
export const addStaffAvatar = rootRoute + "website/staff/addStaffAvatar"


export const getGeneralStaff = rootRoute + "unprotected/website/staff/getGeneralStaff"
export const addGeneralStaff = rootRoute + "website/staff/addGeneralStaff"
export const updateGeneralStaff = rootRoute + "website/staff/updateGeneralStaff"

//Notice
export const getAllNotice = rootRoute + "unprotected/website/notice/getAllNotice"
export const addNotice = rootRoute + "website/notice/addNotice"
export const updateNotice = rootRoute + "website/notice/updateNotice"
export const noticeDelete = rootRoute + "website/notice/noticeDelete"

//Event
 export const getAllEvent = rootRoute + "unprotected/website/event/getAllEvent"
 export const addEvent = rootRoute + "website/event/addEvent"
 export const updateEvent = rootRoute + "website/event/updateEvent"
 export const deleteEvent = rootRoute + "website/event/deleteEvent"
 export const updateEventImage = rootRoute + "website/event/updateEventImage"

 //About
 export const getAisjAbout = rootRoute + "unprotected/website/about/getAisjAbout"
 export const getFounderAbout = rootRoute + "unprotected/website/about/getFounderAbout"
 export const getDirectorAbout = rootRoute + "unprotected/website/about/getDirectorAbout"
 export const updatedAbout = rootRoute + "website/about/updatedAbout"






